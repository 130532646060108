/* You can add global styles to this file, and also import other style files */
:root{
    --bs-primary: #1D2335;
    --bs-primary-85: hsla(225, 29%, 16%, 0.85);
    --bs-primary-70: hsla(225, 29%, 16%, 0.70);
    --bs-primary-30: hsla(225, 29%, 16%, 0.30);
    --bs-secondary: #006EC8;
    
    --bs-success: #198754;
    --bs-info: #6699CC;
    --bs-info-ligth:rgb(119, 162, 205);
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;

    --bs-localteam: #006EC8;
    --bs-visitorteam: #C75D00;
    --bs-localteam-30: hsla(207, 100%, 39%, 0.3);
    --bs-visitorteam-30: hsla(28, 100%, 39%, 0.3);

    --bs-dark: #212529;
    --bs-white-80:hsla(0, 0%, 100%, 0.80);
    --bs-white-65:hsla(0, 0%, 100%, 0.65);
    --bs-white-50:hsla(0, 0%, 100%, 0.5);
}

html, body {
    height: 100%;
}

.navbar-brand{
    color: #fff !important;
}

.bg-primary{
    background-color: var(--bs-primary) !important;
}

.phase-section{
    background-image: url('/assets/imgs/cancha.png') !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #1D2335;
}

.clear-title{
    color:#fff;
    font-weight: 700;
}

.card{
    border-radius: 10px !important;
}

.card-clear{
    background-color: var(--bs-white-65) !important;
}

.clean-shadow{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.icon-button{
    width: 42px;
}

.form-switch{
    display: inline-block !important;
}

.form-check-input{
    border:solid 2px var(--bs-primary);
    color:var(--bs-primary);
    background-color: #f8f9fa !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231D2335'/%3e%3c/svg%3e") !important;
}

.form-check-input:checked{
    border:solid 2px var(--bs-primary);
    color:var(--bs-primary);
    background-color: #f8f9fa !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231D2335'/%3e%3c/svg%3e") !important;
}


.form-check-input-clean{
    border:solid 2px #FFFFFF;
    color:var(--bs-primary);
    background-color: var(--bs-primary) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e") !important;
}

.form-check-input-clean:checked{
    border:solid 2px #FFFF;
    color:var(--bs-primary);
    background-color: var(--bs-primary) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e") !important;
}

.switch-select{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
}